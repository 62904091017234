import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { useDispatch } from 'react-redux';
import { setLocale, setThemeDirection } from './store/actions';
import { useState } from "react";
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';


const LocaleSelector = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const { i18n } = useTranslation();

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleLanguageSelect = (language) => {
        dispatch(setLocale(language));
        dispatch(setThemeDirection(language === 'en' ? 'ltr' : 'rtl'));
        i18n.changeLanguage(language);
        handleCloseMenu();
    };

    return (
        <Box>
          <IconButton
            className="largeIconButton"
            aria-controls="language-menu"
            aria-haspopup="true"
            onClick={handleOpenMenu}
            color="inherit" // You can customize the color
          >
            <LanguageIcon />
          </IconButton>
          <Menu
            id="language-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => handleLanguageSelect('en')}>{t('language.english')}</MenuItem>
            <MenuItem onClick={() => handleLanguageSelect('he')}>{t('language.hebrew')}</MenuItem>
            {/* Add more language options as needed */}
          </Menu>
        </Box>
      );
}

export default LocaleSelector;