import React from 'react';
import { 
    Typography, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { BlobServiceClient } from '@azure/storage-blob';

const sasToken = "sv=2022-11-02&ss=bf&srt=o&sp=r&se=2025-07-19T00:49:45Z&st=2024-07-18T16:49:45Z&spr=https,http&sig=3c47COSJ9%2BYN8sIym20Cyj3jEO4u3KVAHE%2FXbGXS5OY%3D";
const containerName = "data";
const storageAccountName = "ehldata";

export default function SourceModal({source, open, handleClose}){
    const theme = useTheme();
    
    const downloadFile = async () => {
        try {
            const blobName = 'docs/' + source.title;
            const blobService = new BlobServiceClient(
            `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
            );

            const containerClient = blobService.getContainerClient(containerName);
            const blobClient = containerClient.getBlobClient(blobName);
            const downloadBlockBlobResponse = await blobClient.download();
            const blob = await downloadBlockBlobResponse.blobBody;

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = source.title;
            link.click();

            // Clean up
            window.URL.revokeObjectURL(link.href);
            return blobName;
        } catch (error) {
            console.error('Error uploading file:', error.message);
            return null;
        }
    };

    return (
        source && 
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        sx={{
            direction: theme.direction
        }}
      >
        <DialogTitle id="dialog-title">
            <Typography id="modal-title" variant="h6" component="h2">
                {source.title}
            </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant='body' component='p' id="modal-description" sx={{ mt: 2 }}>
                {source.content}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('sources.close')}
          </Button>
          <Button onClick={downloadFile} autoFocus>
            {t('sources.download')}
          </Button>
        </DialogActions>
      </Dialog>
    )
}