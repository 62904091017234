import { 
    Container,
    Box,
    Typography,
    Button,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useSelector, useDispatch } from 'react-redux';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import logo from './assets/logo.png';
import { t } from 'i18next';
import { setUser } from './store/actions';

export default function Login(){
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const handleSuccess = async (tokenResponse) => {
        
        const fetchUserInfo = async () => {
            try {
              const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${tokenResponse.access_token}`
                }
              });
          
              if (!response.ok) {
                throw new Error('Network response was not ok' + response.statusText);
              }
          
              const userInfo = await response.json();
            
              const apiUrl = process.env.REACT_APP_API_URL;
              console.log('apiurl', apiUrl);
              const res = await fetch(`${apiUrl}/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ info: userInfo }),
              });
              const user = await res.json();
              console.log(user);
              dispatch(setUser(user));
              return user;
            } catch (error) {
              console.error('There has been a problem with your fetch operation:', error);
            }
        }
        const userInfo = await fetchUserInfo();
        console.log(userInfo);
        
    }

    const handleError = (error) => {
        console.log(error);
        // Handle login error, possibly dispatch an action to show error message
    }

    const login = useGoogleLogin({
        onSuccess: handleSuccess,
        onError: handleError
    })

    return (
        <Container maxWidth='md'>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <Box sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    borderRadius: 2,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <img src={logo} alt="App Logo" style={{ width: '150px', marginBottom: '20px' }} />
                    <Typography variant="h6" component="h1" gutterBottom>
                        {t('header.slogan')}
                    </Typography>
                    <Button 
                        variant='outlined'
                        onClick={login}
                        endIcon={<GoogleIcon/>}
                        sx={{
                            m:4
                        }}
                    >
                        {t('header.glogin')}
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}
