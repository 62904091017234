import { 
    Box,
} from '@mui/material';
import { useMemo, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hooks } from 'botframework-webchat-component';
import AdvisorConversation from './AdvisorConversation';
import AdvisorHome from './AdvisorHome';
const { useActivities, useActiveTyping } = hooks;
import Sidebar from './Sidebar';
import { styled } from '@mui/material/styles';
import AdvisorHeader from './AdvisorHeader';

const MainContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, width }) => ({
      width:'100%',
      backgroundColor: theme.palette.background.default,
      direction: theme.direction,
      flexGrow: 1,
      padding: theme.spacing(0),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(theme.direction === 'ltr' && {
        marginLeft: `calc(${theme.spacing(9)} + 1px)`
      }),
      ...(theme.direction === 'rtl' && {
        marginRight: `calc(${theme.spacing(9)} + 1px)`
      }),
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        ...(theme.direction === 'ltr' && {
          marginLeft: `${width}px`
        }),
        ...(theme.direction === 'rtl' && {
          marginRight: `${width}px`
        }),
      })
    }),
  );


export default function AdvisorView(){
    const lastConversation = useSelector((state) => state.lastConversation);
    const sidebarOpen = useSelector((state) => state.sidebarOpen);
    const drawerWidth = useSelector((state) => state.drawerWidth);
    const [isStarted, setIsStarted] = useState(false);

    useMemo(() => {
      const activities = lastConversation?.activities.filter(({ type }) => type === 'message')
      .filter(({ channelData: { postBack } = {}, from: { role } }) => !(role === 'user' && postBack)) || [];

      const activitiesExists = activities.length > 0
      if(isStarted != activitiesExists){
          setIsStarted(activitiesExists);
        }
        console.log(isStarted);
    },[lastConversation]);

    return (
        <>
            <AdvisorHeader />
            <Sidebar />
            <MainContainer open={sidebarOpen} width={drawerWidth}>
                {
                    isStarted ? <AdvisorConversation/> : <AdvisorHome />
                }
            </MainContainer>
        </>
    )
}