import {
  SET_USER,
  LOAD_USER,
  SET_LASTCONVERSATION,
  LOAD_LASTCONVERSATION,
  ADD_LASTCONVERSATION_ACTIVITY,
  SET_LOCALE,
  SET_THEME_MODE,
  SET_THEME_DIRECTION,
  SET_SIDEBAR_OPEN,
  SET_HISTORY_OPEN,
  SET_AVATAR_OPEN,
  SET_DISPLAYED_BOT_MESSAGE,
  SET_BOT_TYPING,
  SET_BOT_UPLOADING,
  setThemeDirection
} from './actions';

// Make a more compelling demo than just changing background color

const DEFAULT_STATE = {
  user: null,
  lastConversation: null,
  locale: 'he',
  themeMode: 'light',
  themeDirection: 'rtl',
  drawerWidth: 320,
  sidebarOpen: true,
  historyOpen: false,
  avatarOpen: true,
  lastBotMessage: {}
};

const reducers = (state = DEFAULT_STATE, { payload, type }) => {
  switch (type) {
    case SET_USER:
      state = { ...state, user: payload.user };
      break;
    case LOAD_USER:
      state = {
          ...state,
          user: payload,
      };
      break;
    case SET_LASTCONVERSATION:
      state = { ...state, lastConversation: payload.lastConversation };
      break;
    case LOAD_LASTCONVERSATION:
      state = {
          ...state,
          lastConversation: payload,
      };
      break;
    case ADD_LASTCONVERSATION_ACTIVITY:
      const { activity } = payload;
      state.lastConversation.activities.push(activity);
      const modified = {
        ...state.lastConversation
      }
      state = {
        ...state,
        lastConversation: modified
      }
      break;
    case SET_LOCALE:
      state = { ...state, locale: payload.locale};
      reducers(state, {
        payload: setThemeDirection(payload.locale === 'en' ? 'ltr' : 'rtl'),
        type: SET_THEME_DIRECTION
      });
      break;
    case SET_THEME_MODE:
      state = { ...state, themeMode: payload.themeMode };
      break;
    case SET_THEME_DIRECTION:
      state = { ...state, themeDirection: payload.themeDirection };
      break;
    case SET_SIDEBAR_OPEN:
      state = { ...state, sidebarOpen: payload.sidebarOpen };
      break;
    case SET_HISTORY_OPEN:
      state = { ...state, historyOpen: payload.historyOpen };
      break;
    case SET_AVATAR_OPEN:
        state = { ...state, avatarOpen: payload.avatarOpen };
        break;
    case SET_DISPLAYED_BOT_MESSAGE:
      state = { ...state, lastBotMessage: payload.message };
      break;
    case SET_BOT_TYPING:
      state = { ...state, isBotTyping: payload.typing };
      break;
    case SET_BOT_UPLOADING:
      state = { ...state, isBotUploading: payload.uploading };
      break;
    default:
      break;
  }

  return state;
}

export default reducers;