import React from 'react';
import { 
    Container, 
    Typography, 
    Box, 
    List,
    IconButton,
    Paper,
    LinearProgress,
    Stack,
    CardContent,
    Button,
    Chip
} from '@mui/material';
import { Share, Source } from '@mui/icons-material';
import AdvisorSendbox from './AdvisorSendbox';
import { hooks } from 'botframework-webchat-component';
import { useMemo, useState, useCallback, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import NotesIcon from '@mui/icons-material/Notes';
import { t } from 'i18next';
import Sources from './Sources';
import { styled, useTheme } from '@mui/material/styles';
import SourceModal from './SourceModal';
import { useSelector } from 'react-redux';

const { useActivities, useActiveTyping } = hooks;

const getValueOrUndefined = (object, ...path) => {
    if (typeof object !== 'undefined' && path.length) {
      const key = path.shift();
  
      return getValueOrUndefined(object[key], ...path);
    } else { 
      return object;
    }
  }

const ConversationBox = styled('div', {})(({ theme, width, open })=>({
  position: 'relative', 
  minHeight: '100vh',
  paddingTop: theme.spacing(4),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(14),
  margin: 3,
  boxShadow: 1,
  borderRadius: 2,
  ...(open && {
    ...(theme.direction === 'rtl' && {
      marginRight: `${width}px`
    }),
  })
}))

const AdvisorConversation = () => {
  const theme = useTheme();
  const [activities] = useActivities();
  const lastConversation = useSelector((state) => state.lastConversation);
  const sidebarOpen = useSelector((state) => state.sidebarOpen);
  const drawerWidth = useSelector((state) => state.drawerWidth);
  const [messages, setMessages] = useState([]);
  const activityTyping = useActiveTyping();
  const [isBotTyping, setIsBotTyping] = useState(false);
  const bottomRef = useRef(null);
  const [selectedSource, setSelectedSource] = useState(null)
  const [open, setOpen] = useState(false);

  useMemo(()=>{
      const obj = activityTyping[0];
      const isEmpty = Object.keys(obj).length === 0 && obj.constructor === Object;
      const isTyping = !isEmpty;
      if(isTyping != isBotTyping){
        setIsBotTyping(isTyping);
      }
  },[activityTyping])

  const formatDocumentLink = (text, documents) => {
    const result = text.replace(/\[doc(\d+)\]/g, function(match, index) {
      index = parseInt(index);
      const filename = documents[index-1].title;
      return `[${index}](${filename})`;
    })
    return result;
  }
  
  useMemo(() => {
      const result = lastConversation?.activities
        // Currently, this sample only displays an activity of type "message"
        .filter(({ type }) => type === 'message')
        .filter(({ channelData: { postBack } = {}, from: { role } }) => !(role === 'user' && postBack))
        .map(activity => ({
            ...activity,
            attachments: activity.attachments || [],
            text: getValueOrUndefined(activity, 'channelData', 'messageBack', 'displayText') || activity.text,
            documents: activity.value ? JSON.parse(activity.value) : []
          }))
          .map(activity => ({
            ...activity,
            text: formatDocumentLink(activity.text, activity.documents)
          }))
          // Filter out all empty messages (no attachments or text)
          .filter(({ attachments, text }) => attachments.length || text);
      //console.log(result);
      setMessages(result);
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  },[lastConversation]);

  const sortedMessages = useCallback(()=>{
      const groupedMessages = groupAndSortMessages(messages);
      //console.log(groupedMessages);
      return groupedMessages;
  },[messages]);

  function groupAndSortMessages(messages) {
      const messageMap = {};

      // Initialize the message map
      messages.forEach(message => {
          messageMap[message.id] = { ...message, replies: [] };
      });

      // Group messages by replyToId
      messages.forEach(message => {
          if (message.replyToId) {
              if (messageMap[message.replyToId]) {
                  messageMap[message.replyToId].replies.push(messageMap[message.id]);
              }
          }
      });

      // Sort replies by timestamp
      Object.keys(messageMap).forEach(key => {
          messageMap[key].replies.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      });

      // Collect messages that are not replies to any other message
      const groupedMessages = [];
      messages.forEach(message => {
          if (!message.replyToId) {
              groupedMessages.push(messageMap[message.id]);
          }
      });

      // Sort top-level messages by timestamp
      groupedMessages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      return groupedMessages;
  }

  const handleOpenSource = (title, documents) => {
      const source = documents.find(document=>document.title===title);
      setSelectedSource(source)
      setOpen(true);
  }

  const handleClose = () => {
      setOpen(false);
      setSelectedSource(null);
  }

  return (
    <>
    <ConversationBox open={sidebarOpen} width={drawerWidth}>
      {sortedMessages().map((message)=>(
        <Box key={message.id} sx={{
          m:2,
          p:2
        }}>
          <Typography variant="h1" component="h1" gutterBottom>{message.text}</Typography>
          {message.replies.map((reply, index)=>(
          <>
            <Box key={index}>
              <Stack
                direction="row"
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <NotesIcon className="largeIcon" />
                <Typography variant="h2" component="p" sx={{ mx: 1 }}>
                  {t('conversation.sources')}
                </Typography>
              </Stack>
              <Sources documents={reply.documents} setSelectedSource={setSelectedSource} setOpen={setOpen}/>
            </Box>
            <Box sx={{
              padding: 2,
            }}>
              <Stack direction="row" sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
                <OnlinePredictionIcon className='largeIcon'/>
                <Typography variant="h2" component="p" sx={{mx:1}}>{t('conversation.answer')}</Typography>
              </Stack>
              <Paper sx={{p:1, m:1}}>
                <ReactMarkdown
                  components={{
                    // Customize the rendering of anchor tags
                    a: ({ node, ...props }) => {
                      console.log('node',node);
                      console.log('props', props);
                      return (
                      <Chip label={props.children} onClick={()=>handleOpenSource(props.href, reply.documents)}/>
                    )},
                  }}
                >{reply.text}</ReactMarkdown>
                <Box sx={{
                  display:'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}>
                  <IconButton color="primary" aria-label="share">
                    <Share />
                  </IconButton>
                </Box>
              </Paper>
            </Box>
          </>
          ))}
        </Box>
      ))}
      {isBotTyping && (<LinearProgress sx={{my:2}} ref={bottomRef}/>)}
    </ConversationBox>
    <AdvisorSendbox />
    <SourceModal open={open} source={selectedSource} handleClose={handleClose} />
    </>
  );
};

export default AdvisorConversation;
