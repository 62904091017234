import React, { useEffect } from 'react';
import { enUS, heIL} from '@mui/material/locale';
import { CssBaseline } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Login from './Login';
import AdvisorApp from './AdvisorApp';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { loadUserFromLocalStorage, loadLastConversationFromLocalStorage } from './store/actions';

export default function Main() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const locale = useSelector((state) => state.locale);
    const themeDirection = useSelector((state) => state.themeDirection);
    const themeMode = useSelector((state) => state.themeMode);

    useEffect(() => {
      dispatch(loadUserFromLocalStorage());
      dispatch(loadLastConversationFromLocalStorage());
    }, [dispatch]);


    const theme = createTheme({
        direction: themeDirection,
        typography:{
          fontFamily: locale === 'he' ? 'Alef, Roboto Medium' : 'Roboto, Roboto Serif',
          h1: {
            fontSize: '2.25rem',
            fontWeight: 'bold'
          },
          h2: {
            fontSize: '1.5rem',
            fontWeight: 'bold'
          },
          body1: {
            fontSize: '1rem',
          },
          h6: {
            fontSize: '0.8rem',
            fontWeight: 'bold'
          },
          button: {
            fontSize: '1rem',
          },
        },
        palette: {
          mode: themeMode,
          primary: {
            main: themeMode === 'dark' ? '#ffffff' : '#14213D' ,
          },
          background: {
            default: themeMode === 'dark' ? '#121212' : '#fafafb', // Set default background color based on theme mode
            paper: themeMode === 'dark' ? '#1e1e1e' : '#ffffff', // Set Paper background color based on theme mode
          },
        },
        components: {
          MuiSvgIcon:{
            styleOverrides: {
              root: {
                '&.smallIcon': {
                  fontSize: '1.5rem',
                },
                '&.largeIcon': {
                  fontSize: '2.25rem',
                },
              }
            }
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                '&.smallIconButton': {
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem',
                  },
                },
                '&.largeIconButton': {
                  '& .MuiSvgIcon-root': {
                    fontSize: '2.25rem',
                  },
                },
              }
            }
          }
        }
      },locale==='en' ? enUS : heIL )
      
    return (
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        {
            user ? <AdvisorApp /> : <Login />
        }
    </ThemeProvider>
    )
}