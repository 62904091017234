import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import SearchIcon from '@mui/icons-material/Search';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import logo from './assets/logo.png';
import logoDark from './assets/logo-dark.png';
import daizy from './assets/daizy.png';
import daizySecondary from './assets/daizy.png';
import { useSelector, useDispatch } from 'react-redux';
import { setSidebarOpen, setLastConversation } from './store/actions';
import LocaleSelector from './LocaleSelector';
import ThemeModeSelector from './ThemeModeSelector';
import { t } from 'i18next';

const openedMixin = (theme, drawerWidth) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(13)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(14)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, drawerwidth }) => ({
    width: drawerwidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme, drawerwidth),
      '& .MuiDrawer-paper': openedMixin(theme, drawerwidth),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Sidebar() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const drawerWidth = useSelector((state) => state.drawerWidth);
  const themeMode = useSelector((state) => state.themeMode);
  const sidebarOpen = useSelector((state) => state.sidebarOpen);
  const user = useSelector((state) => state.user);
  const locale = useSelector((state) => state.locale);
  const [libraryOpen, setLibraryOpen] = useState(true);
  const isDarkMode = theme.palette.mode === 'dark';

  const handleDrawerToggle = () => {
    dispatch(setSidebarOpen(!sidebarOpen));
  };

  const handleLibraryToggle = () => {
    setLibraryOpen(!libraryOpen);
    if (!sidebarOpen) {
      dispatch(setSidebarOpen(true));
    }
  };

  const handleSelectConversation = (conversation) => {
    dispatch(setLastConversation(conversation));
  }

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return date.toLocaleDateString(locale === 'en' ? 'en-US' : 'he-IL', options);
  };

  

  return (
    <Box sx={{ display: 'flex', direction: theme.direction }}>
      <Drawer
        background={theme.palette.background.default}
        variant="permanent"
        open={sidebarOpen}
        drawerwidth={drawerWidth}
        anchor={theme.direction === 'ltr' ? 'left' : 'right'}
      >
        <Box sx={{ minHeight: 110, p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            {sidebarOpen ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                  <img src={isDarkMode ? logoDark : logo} alt="deebee.ai logo" width={190} />
                </Box>
                <IconButton onClick={handleDrawerToggle} className="largeIconButton">
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </Box>
            ) : (
              <IconButton onClick={handleDrawerToggle} className="largeIconButton">
                <img src={isDarkMode ? daizySecondary : daizy} alt="deebee.ai daizy" width={30} />
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <List sx={{ padding: 2, direction: theme.direction === 'rtl' ? 'rtl' : 'ltr' }}>
          <ListItemButton
            sx={{
              bgcolor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300],
              color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
              justifyContent: sidebarOpen ? 'flex-start' : 'center',
            }}
          >
            <ListItemIcon sx={{ minWidth: 0, mr: sidebarOpen ? 3 : 'auto', justifyContent: 'center' }}>
              <AddIcon className="largeIconButton" />
            </ListItemIcon>
            {sidebarOpen && (
              <ListItemText primary={t('sidebar.new')} sx={{ textAlign: theme.direction === 'rtl' ? 'right' : 'left' }} />
            )}
          </ListItemButton>
          <ListItemButton sx={{ justifyContent: sidebarOpen ? 'flex-start' : 'center' }}>
            <ListItemIcon sx={{ minWidth: 0, mr: sidebarOpen ? 3 : 'auto', justifyContent: 'center' }}>
              <SearchIcon className="largeIconButton" />
            </ListItemIcon>
            {sidebarOpen && (
              <ListItemText primary={t('sidebar.home')} sx={{ textAlign: theme.direction === 'rtl' ? 'right' : 'left' }} />
            )}
          </ListItemButton>
          <ListItemButton sx={{ justifyContent: sidebarOpen ? 'flex-start' : 'center' }} onClick={handleLibraryToggle}>
            <ListItemIcon sx={{ minWidth: 0, mr: sidebarOpen ? 3 : 'auto', justifyContent: 'center' }}>
              <LocalLibraryIcon className="largeIconButton" />
            </ListItemIcon>
            {sidebarOpen && (
              <ListItemText primary={t('sidebar.library')} sx={{ textAlign: theme.direction === 'rtl' ? 'right' : 'left' }} />
            )}
          </ListItemButton>
          {sidebarOpen && (
            <Collapse 
              in={libraryOpen} 
              timeout="auto" 
              unmountOnExit
              sx={{
                mx: theme.spacing(3)
              }}>
              {user.conversations?.map((conversation, index) => (
                <List component="div" disablePadding key={index}>
                  <ListItemButton onClick={()=>handleSelectConversation(conversation)}>
                    <ListItemIcon>
                      <QuestionAnswerIcon />
                    </ListItemIcon>
                    {sidebarOpen && (
                      <ListItemText
                        primary={formatDate(conversation.updatedAt)}
                        sx={{
                          textAlign: theme.direction === 'rtl' ? 'right' : 'left',
                          fontSize: '0.875rem', // Decreased font size
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      />
                    )}
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          )}
        </List>
        <Box sx={{ flexGrow: 1 }} /> {/* This will push the next list to the bottom */}
        <Divider />
        <Stack direction={sidebarOpen ? 'row' : 'column'} sx={{ m: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LocaleSelector />
          <ThemeModeSelector />
          <IconButton className="largeIconButton">
            <SettingsIcon />
          </IconButton>
        </Stack>
      </Drawer>
    </Box>
  );
}
