import './App.css';
import React from 'react';
import { connect } from 'react-redux';
import { createDirectLine, createStore } from 'botframework-webchat';
import { Components } from 'botframework-webchat-component';
import dispatchIncomingActivityMiddleware from './store/dispatchIncomingActivityMiddleware';
import { Typography, Container } from '@mui/material';
import '@fontsource/roboto';
import '@fontsource/alef'; // Import Alef font
import AdvisorView from './AdvisorView';
import { setUser, setLastConversation, addLastConversationActivity } from './store/actions';

class AdvisorApp extends React.Component {
  constructor(props) {
    super(props);
    const { user, locale, lastConversation, incomingActivity } = props;
    this.store = createStore({}, incomingActivity);

    this.state = {
      user,
      locale,
      lastConversation,
      watermark: null,
      directLine: null,
    };
  }

  componentDidMount() {
    this.fetchToken();
  }

  componentDidUpdate(prevProps) {
    const { locale, user, lastConversation } = this.props;

    if (prevProps.locale !== locale) {
      this.setState({ locale });
    }

    if (prevProps.user !== user) {
      this.setState({ user });
    }

    if (prevProps.lastConversation?.id !== lastConversation?.id) {
      this.setState({ lastConversation });
      this.reloadDirectLine();
    }
  }

  reloadDirectLine() {
    const { directLine } = this.state;
    if (directLine) directLine.end();
    this.fetchToken();
  }

  async fetchToken() {
    const { locale, lastConversation } = this.props;
    const botId = 'cra84_easyHealthyLeaving';
    const langUrl = `https://defaultaa2ed11e70e143ce98cc70921f99ec.45.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cra84_easyHealthyLeaving/directline/token?api-version=2022-03-01-preview`;
  
    try {
      let token = null;
      let conversationId = lastConversation?.id;
      let watermark = null;
      let streamUrl = null;
  
      if (!conversationId) {
        token = await this.fetchDirectLineToken(langUrl);
        const randomValue = Math.random().toString(36).substr(2, 9);
        watermark = `act-${randomValue}`;
        this.setState({ watermark });
      } else {
        token = lastConversation.token;
        watermark = lastConversation.watermark;
        this.setState({ watermark });
        try {
          const reconnectData = await this.fetchReconnectUrl(conversationId, token, watermark);
          conversationId = reconnectData.conversationId;
          token = reconnectData.token;
          streamUrl = reconnectData.streamUrl;
        } catch (error) {
          console.error('Failed to reconnect to conversation:', error);
          throw new Error('Failed to reconnect to conversation.');
        }
      }
  
      const directLineURL = await this.fetchDirectLineUrl(langUrl);
      const directLine = createDirectLine({
        domain: new URL('v3/directline', directLineURL),
        conversationId,
        token,
        watermark
      });
  
      this.initializeDirectLine(directLine, locale, token, streamUrl);
  
      setTimeout(() => this.refreshToken(), (1800 - 300) * 1000);
    } catch (error) {
      console.error('Error fetching Direct Line token or URL:', error);
    }
  }

  async refreshToken() {
    const { directLine, watermark, user } = this.state;
    const oldToken = directLine.token;
    const refreshUrl = `https://europe.directline.botframework.com/v3/directline/tokens/refresh`;

    try {
      const response = await fetch(refreshUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${oldToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }

      const { token, expires_in } = await response.json();
      const conversation = this.saveConversation(directLine.conversationId, token, user.id, watermark);
      const newDirectLine = createDirectLine({ token, watermark });
      this.setState({ directLine: newDirectLine, lastConversation: conversation });

      // Set a new timer to refresh the token before it expires
      setTimeout(() => this.refreshToken(), (expires_in - 300) * 1000); // Refresh 5 minutes before expiration
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  }

  async fetchReconnectUrl(conversationId, token, watermark) {
    try {
      const response = await fetch(`https://europe.directline.botframework.com/v3/directline/conversations/${conversationId}?watermark=${watermark}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`Failed to reconnect to conversation. Status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching reconnect URL:', error);
      throw error;
    }
  }

  async fetchDirectLineUrl(langUrl) {
    try {
      const apiVersion = new URL(langUrl).searchParams.get('api-version');
      const response = await fetch(new URL(`/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`, langUrl));
      if (!response.ok) throw new Error(`Failed to retrieve regional channel settings. Status: ${response.status}`);
      const { channelUrlsById: { directline } } = await response.json();;
      return directline;
    } catch (error) {
      console.error('Error fetching Direct Line URL:', error);
      throw error;
    }
  }

  async fetchDirectLineToken(langUrl) {
    try {
      const response = await fetch(langUrl);
      if (!response.ok) throw new Error(`Failed to retrieve Direct Line token. Status: ${response.status}`);
      const { token } = await response.json();
      return token;
    } catch (error) {
      console.error('Error fetching Direct Line token:', error);
      throw error;
    }
  }

  initializeDirectLine(directLine, locale, token, streamUrl) {
    const CONNECTION_ESTABLISHED = 2;
    const subscription = directLine.connectionStatus$.subscribe({
      next: async (value) => {
        console.log(value);
        if (value === CONNECTION_ESTABLISHED) {
          try {
            await this.startConversation(directLine, locale, token);
            /*if (streamUrl) {
              this.connectToWebSocket(streamUrl);
            }*/
            subscription.unsubscribe();
          } catch (error) {
            console.error('Error sending startConversation event:', error);
          }
        }
      },
      error: (err) => {
        console.error('Error with connectionStatus$ subscription:', err);
      },
    });
  
    this.setState({ directLine });
  }
  

  async startConversation(directLine, locale, token) {
    const { user, watermark } = this.state;

    try {
      await directLine.postActivity({
        localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        locale,
        name: 'startConversation',
        type: 'event',
        value: token,
      }).toPromise();

      const conversationId = directLine.conversationId;
      const lastConversation = await this.saveConversation(conversationId, token, user.id, watermark);
      const activities = await this.fetchConversationHistory(conversationId, token, watermark);
      lastConversation.activities = activities;
      this.props.setLastConversation(lastConversation);
      const conversations = await this.getConversations(user.id);
      user.conversations = conversations;
      this.props.setUser(user);
    } catch (error) {
      console.error('Error starting conversation:', error);
    }
  }

  async fetchConversationHistory(conversationId, token, watermark) {
    try {
      const response = await fetch(`https://europe.directline.botframework.com/v3/directline/conversations/${conversationId}/activities`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error(`Failed to retrieve activities. Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data.activities;
    } catch (error) {
      console.error('Error fetching conversation history:', error);
      throw error;
    }
  }

  async saveConversation(conversationId, token, userId, watermark) {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const conversation = { id: conversationId, userId, token, watermark };

      const res = await fetch(`${apiUrl}/conversation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(conversation),
      });
      if (!res.ok) throw new Error(`Failed to save conversation. Status: ${res.status}`);
      return res.json();
    } catch (error) {
      console.error('Error saving conversation:', error);
      throw error;
    }
  }

  async getConversations(userId) {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const res = await fetch(`${apiUrl}/conversations?userId=${userId}&limit=10`);
      if (!res.ok) throw new Error(`Failed to fetch conversations. Status: ${res.status}`);
      return res.json();
    } catch (error) {
      console.error('Error fetching conversations:', error);
      throw error;
    }
  }

  connectToWebSocket(streamUrl) {
    if (this.webSocket) {
      this.webSocket.close();
    }
  
    this.webSocket = new WebSocket(streamUrl);
  
    this.webSocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.activities) {
        this.handleIncomingActivities(message.activities);
      }
    };
  
    this.webSocket.onclose = () => {
      console.warn('WebSocket connection closed.');
    };
  
    this.webSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }
  
  handleIncomingActivities(activities) {
    activities.forEach(activity => {
      // Handle each activity
      console.log('Received activity:', activity);
    });
  }

  render() {
    const { directLine } = this.state;
    return (
      <>
        {directLine ? (
          <Components.Composer store={this.store} directLine={directLine}>
            <AdvisorView />
          </Components.Composer>
        ) : (
          <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Typography variant='error'>Loading Advisor...</Typography>
          </Container>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  incomingActivity: dispatchIncomingActivityMiddleware(dispatch),
  addActivity: (conversation, activity) => dispatch(addLastConversationActivity(conversation, activity)),
  setUser: (user) => dispatch(setUser(user)),
  setLastConversation: (lastConversation) => dispatch(setLastConversation(lastConversation)),
});

export default connect(
  ({ user, lastConversation, locale, sidebarOpen, drawerWidth, themeMode, themeDirection }) => ({
    user,
    lastConversation,
    locale,
    sidebarOpen,
    drawerWidth,
    themeMode,
    themeDirection,
  }),
  mapDispatchToProps
)(AdvisorApp);
