import { setDisplayedBotMessage, setBotTyping, addLastConversationActivity } from "./actions";



const middleware = (dispatch) => {
    return () => next => action => {
      //console.log(action)
      if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
        const { activity } = action.payload;
        console.log(activity);
        
        if (activity.type === 'event' && activity.from.role === 'bot' && activity.name === 'redux action') {
          dispatch(activity.value);
        }
        if (activity.type === 'event' && activity.from.role === 'user' && activity.name === 'startConversation') {
          console.log('start conversation detected');
        }
        if(activity.type === 'message' && activity.from.role === 'bot' && !activity.speak){
          dispatch(setBotTyping(false));
          dispatch(setDisplayedBotMessage(action.payload.activity));
        }
        if(activity.type === 'message' &&
          !(activity.from.role === 'user' && activity.channelData?.postBack)){
          console.log('middleware activity: ', activity);
          dispatch(addLastConversationActivity(activity));
        }
        if(activity.type === 'typing' && activity.from.role === 'bot'){
          dispatch(setBotTyping(true));
        }

      }
      
      return next(action);
    };
  }

  export default middleware;