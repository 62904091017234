import React, { useState } from 'react';
import { 
    Typography, 
    Box, 
    Card,
    CardContent,
    CardActions,
    Button} from '@mui/material';

import { t } from 'i18next';

export default function Sources ({documents, setSelectedSource, setOpen}) {

    const handleOpenSource = (source) => {
        setSelectedSource(source)
        setOpen(true);
    }

    return (
    <>
        <Box
            sx={{
                display:'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                p:2
            }}
            >
            {documents.map((document, index) => (
                <Card
                key={index}
                sx={{
                    width: 170
                }}
                >
                <CardContent>
                    <Typography variant='h6' component='h6'
                    sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                    }}
                    >{document.title}</Typography>
                    <Typography
                    variant="body2"
                    component="p"
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 5,
                        textOverflow: 'ellipsis',
                        height: 100
                    }}
                    >
                    {document.content}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={()=>handleOpenSource(document)} size="small">{t('sources.view')}</Button>
                </CardActions>
                </Card>
            ))}
        </Box>
    </>
    )
}