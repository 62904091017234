import React, { useCallback, useRef, useState } from 'react';
import { 
  Container, 
  Box, 
  TextField, 
  IconButton, 
  Button, 
  Typography,
  Grid, 
  InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { t } from 'i18next';
import { useTheme, styled } from '@mui/material/styles';
import { hooks } from 'botframework-webchat-component';
import { useSelector } from 'react-redux';

const { useSendMessage } = hooks;

const HomeBox = styled('div', {})(({ theme, width, open })=>({
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  marginTop: theme.spacing(10), 
  minHeight: '100vh',
  marginRight: theme.spacing(10),
  ...(open && {
    ...(theme.direction === 'rtl' && {
      marginRight: `${width}px`
    }),
  })
}))

export default function AdvisorHome(){
    const theme = useTheme();
    const sendMessage = useSendMessage();
    const [boxValue, setBoxValue] = useState('');
    const promptRef = useRef(null);
    const sidebarOpen = useSelector((state) => state.sidebarOpen);
    const drawerWidth = useSelector((state) => state.drawerWidth);

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    };

    const handleValueChange = (event) => {
      setBoxValue(event.target.value);
    };

    const handleSubmit = useCallback(() => {
      const message = promptRef.current.value;
      sendMessage(message);
      setBoxValue('');
    }, [sendMessage]);

    const handleAttach = () => {

    }
  

    return (
      <HomeBox open={sidebarOpen} width={drawerWidth}>
        <Box sx={{
          width: '75%',
          bgcolor: 'background.paper',
          boxShadow: 1,
          borderRadius: 2,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Typography variant="h1" sx={{ textTransform: 'capitalize' }} gutterBottom>
            {t('header.slogan')}
          </Typography>
          <Box  sx={{ display: 'flex', alignItems: 'center', verticalAlign: 'top', width: '100%', mb: 2 }}>
            <TextField
              fullWidth
              inputRef={promptRef}
              onKeyDown={handleKeyPress}
              autoFocus
              onChange={handleValueChange}
              value={boxValue}
              variant="outlined"
              placeholder={t('sendBox.placeholder')}
              multiline
              rows={4}
              InputProps={{
                style: { fontSize: '1.5rem' }, // Adjust the font size as needed
                startAdornment: (
                  <InputAdornment position="start" sx={{ alignSelf: 'flex-end', pb: 3 }}>
                    <IconButton onClick={handleAttach} className="smallIconButton">
                      <AttachFileIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ alignSelf: 'flex-end', pb: 3 }}>
                    <IconButton onClick={handleSubmit} className="smallIconButton">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button variant="outlined" startIcon={<IconButton className="smallIconButton"><SearchIcon sx={{...(theme.direction === 'ltr' ? { mr: 1 } : { ml: 1, mr: 0, pr: 0 })}} /></IconButton>}>
                Upcoming music festivals
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" startIcon={<IconButton className="smallIconButton"><SearchIcon sx={{...(theme.direction === 'ltr' ? { mr: 1 } : { ml: 1, mr: 0, pr: 0 })}} /></IconButton>}>
                Bestselling books released in 2024
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" startIcon={<IconButton className="smallIconButton"><SearchIcon sx={{...(theme.direction === 'ltr' ? { mr: 1 } : { ml: 1, mr: 0, pr: 0 })}} /></IconButton>}>
                Why is magnesium glycinate trending?
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" startIcon={<IconButton className="smallIconButton"><SearchIcon sx={{...(theme.direction === 'ltr' ? { mr: 1 } : { ml: 1, mr: 0, pr: 0 })}} /></IconButton>}>
                What is Perplexity AI?
              </Button>
            </Grid>
          </Grid>
        </Box>
      </HomeBox>
    )
}