import React from 'react';
import { IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useSelector, useDispatch } from 'react-redux';
import { setThemeMode } from './store/actions';

export default function ThemeModeSelector() {
    const dispatch = useDispatch();
    const themeMode = useSelector((state) => state.themeMode);
    const isDark = themeMode === 'dark';

    const toggleThemeMode = () => {
        const mode = isDark ? 'light' : 'dark';
        dispatch(setThemeMode(mode));
    };

    return (
        <IconButton className="largeIconButton" onClick={toggleThemeMode} color="inherit">
            {isDark ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
    );
}
